.c-project {
  border-bottom: rem_calc(2) solid #ddd;

  @include bp-medium('max') {
    max-width: 250px !important;
  }
}

.c-project__title {
  a {
    &:hover {
      color: $color-link;
    }
  }
}

.c-project__category {
  color: $color-grey-medium;
}

.c-project__lead {
  a {
    color: $color-link;
  }
}

.c-project__gallery-image {
  p {
    border: 1px solid $color-grey-light;
  }
}
