.c-form__control {
  &:hover:not([type=file]),
  &:focus:not([type=file]) {
    border: rem-calc(1) solid $color-link;
    background-color: $color-white;
    color: $color-grey-dark;

    &::placeholder {
      color: $color-grey-dark;
    }
  }
}

.c-form-group {
  label,
  legend {
    span {
      color: $color-error;
    }
  }

  .c-field__label {
    font-size: rem_calc(18);
    font-weight: 600;

    @include bp-medium('max') {
      font-size: rem_calc(16);
    }

    .c-icon {
      stroke: $color-theme;
      fill: $color-theme;
    }
  }

  legend {
    color: $color-theme;
  }

  fieldset {
    border: solid 1px #d8dadd;
  }
}

.c-form__control {
  &.c-form__textarea {
    height: rem_calc(200) !important;
  }
}

.c-field__error {
  font-size: rem_calc(14);
  color: $color-error;

  @include bp-medium('max') {
    font-size: rem_calc(13);
  }
}

.c-field__message {
  font-size: rem_calc(16);

  &.show {
    width: 80%;
    color: $color-white;
  }

  &.error {
    background-color: $color-error;
  }

  &.success {
    background-color: $color-success;
  }

  @include bp-xlarge('max') {
    font-size: rem_calc(16);
  }

  @include bp-medium('max') {
    font-size: rem_calc(15);
  }
}

.c-newsletter__form {
  .o-layout {
    width: calc(100% + 26px);
  }

  .c-form__input,
  .c-btn {
    width: 100%;
    height: rem_calc(72);
    font-weight: 500;

    @include bp-xxlarge('max') {
      height: rem_calc(66);
    }

    @include bp-large('max') {
      height: rem_calc(62);
    }

    @include bp-medium('max') {
      height: rem_calc(50);
    }
  }

  @include bp-medium('max') {
    .c-btn {
      font-size: rem_calc(18);
    }
  }

  .c-form__input {
    border-color: $color-white;
    color: $color-grey-dark;

    &:hover,
    &:focus {
      border-color: $color-theme;
    }

    &::placeholder {
      color: $color-grey-dark;
    }
  }
}
