$columns: 12;
$page-blocks-width: $global-max-width + rem_calc(50);

.c-blocks {
  &--container {
    max-width: $global-max-width;
  }

  &-main,
  &-sidebar {
    min-height: 1px;
  }

  &__main {
    width: 100%;

    .c-blocks--has-sidebar & {
      @include bp-large {
        width: 75%;
      }
    }

    .c-blocks--has-sidebar &--8 {
      @include bp-large {
        width: 66.66667%;
      }
    }
  }

  &__sidebar {
    width: 100%;
    max-width: rem_calc($sidebar_max_width);

    &--4 {
      max-width: rem_calc($sidebar_max_width_lg);
    }

    @include bp-large {
      width: 25%;

      &--4 {
        width: 33.33333%;
      }
    }
  }
}

.c-block {
  width: 100%;

  &__header {
    width: 100%;

    span[style*="text-decoration"] { /* stylelint-disable-line string-quotes, selector-attribute-quotes */
      border-bottom: rem_calc(4) solid $color-theme-dark;
    }
  }

  &--clear {
    width: 100%;
  }

  &--container {
    max-width: $global-max-width;

    @include bp-xxlarge {
      max-width: $page-blocks-width;
    }
  }

  &__inner {
    width: 100%;
    max-width: $global-max-width;
    min-height: 100px;

    @include bp-custom($global-max-width) {
      @if($global-max-width > ($bp-xxlarge - 1)) {
        max-width: $page-blocks-width;
        padding: rem_calc(13 23);
      }
    }

    @include bp-xxlarge {
      @if($global-max-width <= ($bp-xxlarge - 1)) {
        max-width: $page-blocks-width;
        padding: rem_calc(13 23);
      }
    }

    &--full {
      width: 100%;
      max-width: rem_calc(2048);
      padding: 0;

      .c-block--type-slider &,
      .c-block--type-gallery &,
      .c-block--type-columns & {
        max-width: 100%;
      }
    }
  }

  &--w-75 .c-block__inner,
  &__content--w-75 {
    @include bp-xlarge {
      max-width: $global-max-width * 0.75;
      width: 75%;
    }

    @include bp-xxlarge {
      max-width: $page-blocks-width * 0.75;
    }
  }

  &--w-66 .c-block__inner,
  &__content--w-66 {
    @include bp-xlarge {
      max-width: $global-max-width * 0.66;
      width: 66%;
    }

    @include bp-xxlarge {
      max-width: $page-blocks-width * 0.66;
    }
  }

  &--w-50 {
    width: 50%;
    max-width: 50%;

    @include bp-large('max') {
      width: 100%;
      max-width: 100%;
    }
  }

  &__content--w-50 {
    @include bp-xlarge {
      max-width: $global-max-width * 0.75;
      width: 75%;
    }

    @include bp-xlarge-medium {
      max-width: $global-max-width * 0.5;
      width: 50%;
    }

    @include bp-xxlarge {
      max-width: $page-blocks-width * 0.5;
    }
  }
}

@for $i from 5 through 8 {
  div[class*='c-block--h-fixed-#{$i}'] { /* stylelint-disable-line selector-attribute-quotes */
    @include bp-xxlarge('max') {
      @if( $i >= 7) {
        min-height: rem_calc(600) !important;
      }
    }

    @include bp-large('max') {
      min-height: rem_calc(500) !important;
    }

    @include bp-medium('max') {
      min-height: rem_calc(400) !important;
    }
  }
}

.owl-nav {
  .icon {
    fill: $color-white;

    .c-block__columns-arrows--dark &,
    .c-block__slider-arrows--dark & {
      fill: $color-theme;
    }
  }
}

.owl-dots {
  .owl-dot {
    background-color: $color-grey-super-light;
    border: rem_calc(1) solid $color-theme-dark;

    .c-block__slider-dots--dark &,
    .c-block__gallery-dots--dark &,
    .c-block__columns-dots--dark & {
      background-color: $color-theme;
      border-color: $color-theme-dark;
    }
  }

  .owl-dot.active {
    background-color: $color-theme;
    border-color: $color-theme-dark;

    .c-block__slider-dots--dark &,
    .c-block__gallery-dots--dark &,
    .c-block__columns-dots--dark & {
      background-color: $color-white;
      border: rem_calc(1) solid $color-theme-dark;
    }
  }
}

.c-block__slider {
  &-slide {
    &-caption {
      &-container {
        max-width: $global-max-width;
      }

      &-inner {
        padding: rem_calc(40);
        border: solid rem_calc(5) rgba(255, 255, 255, 0.3);
        font-size: rem_calc(20);

        p {
          padding-top: rem_calc(10);
          line-height: rem_calc(30);
        }

        @include bp-large('max') {
          padding: rem_calc(25);
          font-size: rem_calc(18);
        }

        @include bp-medium('max') {
          padding: rem_calc(15);
          font-size: rem_calc(16);

          p {
            line-height: rem_calc(24);
          }
        }

        .c-block__slider--full & {
          max-width: $global-max-width - rem_calc(26);
        }
      }

      &--50 {
        width: 100%;

        @include bp-medium {
          width: 75%;
        }

        @include bp-large {
          width: 50%;

          .c-blocks--has-sidebar & {
            width: 75%;
          }
        }
      }

      &--75 {
        width: 100%;

        @include bp-medium {
          width: 75%;
        }
      }
    }
  }
}

.c-slider__donate {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  max-width: $page-blocks-width;
  width: 100%;
  margin: 0 auto;
  text-align: right;

  @include bp-large('max') {
    text-align: center;
  }

  .c-btn {
    padding: rem_calc(25 40);
    margin: 0 rem_calc(10) 0 0;
    font-size: rem_calc(36);
    background-color: rgba(36, 234, 196, 0.6);
    border: none;

    &:hover {
      background-color: rgba(36, 234, 196, 1);
    }

    @include bp-xxlarge('max') {
      padding: rem_calc(20 30);
      font-size: rem_calc(28);
    }

    @include bp-large('max') {
      padding: rem_calc(15 20);
      font-size: rem_calc(24);
    }
  }
}

.c-block__columns {
  &-column {
    &-title {
      color: $color-theme;
    }
  }
}

.c-block__tabs {
  &-nav {
    li {
      border: rem_calc(1) solid $color-grey-lighter;

      .c-block__tabs--dark & {
        border-color: $color-grey-dark;
      }
    }

    .active {
      a {
        background-color: $color-grey-lighter;

        .c-block__tabs--dark & {
          background-color: $color-grey-dark;
          color: $color-white;
        }
      }
    }

    a {
      background-color: $color-grey-super-light;

      .c-block__tabs--dark & {
        background-color: $color-grey;
        color: $color-white;
      }

      &:hover {
        background-color: $color-grey-lighter;

        .c-block__tabs--dark & {
          background-color: $color-grey-dark;
          color: $color-white;
        }
      }
    }
  }

  &-content {
    background-color: $color-grey-lighter;

    .c-block__tabs--dark & {
      background-color: $color-grey-dark;
      color: $color-white;
    }
  }

  &-accordion {
    &-header {
      a {
        border-bottom: rem_calc(1) solid $color-grey-light;
        background-color: $color-white;

        .c-block__tabs--dark & {
          background-color: $color-grey;
          color: $color-white;
        }
      }

      .c-arrow-icon {
        fill: $color-grey-dark;

        .c-block__tabs--dark & {
          fill: $color-grey-super-light;
        }
      }

      &.active {
        a {
          border-bottom: 1px solid $color-white;
          background-color: $color-white;

          .c-block__tabs--dark & {
            background-color: $color-grey-dark;
            color: $color-white;
          }
        }
      }
    }

    &-content {
      background-color: $color-grey-lighter;

      .c-block__tabs--dark & {
        background-color: $color-grey-dark;
        color: $color-white;
      }
    }
  }
}

.c-block__testimonials {
  max-width: calc(100% - 70px);


  .owl-nav {
    .owl-prev,
    .owl-next {
      background-color: $color-grey-lighter;

      &:hover {
        background-color: $color-grey-light;
      }

      &.invisible,
      &.invisible:hover {
        background-color: $color-grey-super-light;
      }
    }
  }

  &-item {
    background-color: $color-grey-super-light;

    .icon-quote {
      fill: #d6d6d6;
    }
  }

  &-meta {
    img {
      width: rem_calc(60) !important; // to override default owl carousel 100% width
    }
  }

  &-info {
    p {
      font-size: rem_calc(14);
    }
  }
}


.c-block__social-media {
  &-widget {
    &-instafeed {
      a {
        background-color: $color-grey-super-light;
      }

      span {
        color: $color-grey;
        font-size: rem_calc(14);
      }
    }
  }
}

.c-block__newsletters-list {
  &-item-inner {
    max-width: rem_calc(410);
    border: 1px solid $color-grey-super-light;
  }
}

.c-instagram__data {
  background-color: rgba(0, 0, 0, 0.8);

  &-inner {
    max-width: rem_calc(500);
    background-color: $color-white;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
  }
}

.c-block__contact-form {
  .cform-container {
    left: - rem_calc(13);
    width: calc(100% + 26px);
  }
}
