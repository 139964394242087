body {
  font-size: rem_calc(16);
}

p {
  font-size: inherit;
  line-height: rem_calc(24);

  .u-text--enlarge {
    font-size: rem_calc(26);
  }

  .u-text--ensmall {
    font-size: rem_calc(16);
  }

  @include bp-custom(1680, 'max') {
    .u-text--enlarge {
      font-size: rem_calc(24);
    }
  }

  @include bp-xxlarge('max') {
    .u-text--enlarge {
      font-size: rem_calc(22);
    }
  }

  @include bp-xlarge('max') {
    .u-text--enlarge {
      font-size: rem_calc(20);
    }
  }

  @include bp-medium('max') {
    font-size: rem_calc(16);

    .u-text--enlarge {
      font-size: rem_calc(18);
    }

    .u-text--ensmall {
      font-size: rem_calc(14);
    }
  }
}

.u-text--light {
  font-weight: 300;
}
