.c-heading {
  border-bottom: rem-calc(4) solid $color-underline-light;
  color: $color-black;
}

.c-heading--secondary {
  color: $color-theme;
}

.c-page__title {
  margin-top: rem_calc(30);
  color: $color-theme;
}
