.c-main-nav__items {
  padding: rem_calc(50) 0 0 0;

  .c-header--static.c-header--image & {
    padding-top: rem_calc(123);

    @include bp-xxlarge('max') {
      padding-top: rem_calc(93);
    }
  }
}

.c-main-nav__item {
  &.menu-item-has-children {
    &:after,
    &:before {
      border-left: rem-calc(5) solid transparent;
      border-right: rem-calc(5) solid transparent;
      border-top: rem-calc(7) solid;
    }

    &:before {
      bottom: - rem_calc(9);
      border-top-color: transparent;
    }
  }
}

.c-main-nav__link {
  padding-top: rem-calc(12);
  padding-bottom: rem-calc(12);
  font-size: rem_calc(20);
  text-transform: uppercase;
  text-decoration: none !important;

  .c-main-nav__item--transparent & {
    .c-header--static.c-header--image &,
    .c-header--static.c-header--none &,
    .c-header--none & {
      padding-top: rem-calc(31);
      padding-bottom: rem-calc(31);
    }
  }
}

.c-main-nav__sublink {
  text-transform: uppercase;
  text-decoration: none !important;
}

.c-main-nav__search-trigger {
  margin-left: rem-calc(15);

  @include bp-xlarge-medium {
    margin-left: rem-calc(55);
  }
}
