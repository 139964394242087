.c-listing__title {
  margin-bottom: rem_calc(20);

  @include bp-large('max') {
    margin-bottom: rem_calc(15);
  }

  &--right {
    margin-top: 0;
  }
}

.c-listing__item {
  border-bottom: rem-calc(2) solid $color-grey-light-medium;
}

.c-listing__link {
  color: $color-theme;
  text-decoration: none;
  transition: $global-transition;

  &:hover {
    color: $color-link;
  }
}
