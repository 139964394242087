.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: $global-max-width;
  position: relative;
}

body {
  border: rem_calc(5) solid transparent;
  box-sizing: border-box;

  @include bp-small {
    border: rem_calc(15) solid transparent;
  }

  @include bp-large {
    border: rem_calc(20) solid transparent;
  }

  @include bp-xxlarge {
    border: rem_calc(30) solid transparent;
  }
}
