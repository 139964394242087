select, textarea, input[type=text], input[type=password],
input[type=datetime], input[type=datetime-local],
input[type=date], input[type=month], input[type=time],
input[type=week], input[type=number], input[type=email],
input[type=url], input[type=tel] {
  height: rem_calc(38);
  padding: rem-calc(15);
  border: rem-calc(1) solid $color-grey;
  color: $color-link;
  font-size: rem_calc(16);
  font-weight: 600;
  background-color: $color-white;

  @include bp-xxlarge('max') {
    height: rem_calc(38);
  }

  @include bp-large('max') {
    height: rem_calc(38);
  }

  @include bp-medium('max') {
    height: rem_calc(36);
    padding: rem-calc(5 10);
    font-size: rem_calc(15);
    border-width: rem-calc(1);
  }

  &::placeholder {
    color: $color-link;
    transition: $global-transition;
  }

  &:hover,
  &:focus {
    border: rem-calc(1) solid $color-theme-dark;

    @include bp-medium('max') {
      border-width: rem-calc(1);
    }
  }
}

label {
  color: $color-black;
  line-height: 1.6em;
}
