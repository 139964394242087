/*
  Project: Press Plus
  Author: Xfive
 */
/* 01. Global settings
   ========================================================================== */
/* 02. Breakpoints
   ========================================================================== */
/* 03. Colors
   ========================================================================== */
select, textarea, input[type=text], input[type=password],
input[type=datetime], input[type=datetime-local],
input[type=date], input[type=month], input[type=time],
input[type=week], input[type=number], input[type=email],
input[type=url], input[type=tel] {
  height: 2.375rem;
  padding: 0.9375rem;
  border: 0.0625rem solid #bfbfbf;
  color: #57accb;
  font-size: 1rem;
  font-weight: 600;
  background-color: #fff;
}

@media (max-width: 85.4375rem) {
  select, textarea, input[type=text], input[type=password],
  input[type=datetime], input[type=datetime-local],
  input[type=date], input[type=month], input[type=time],
  input[type=week], input[type=number], input[type=email],
  input[type=url], input[type=tel] {
    height: 2.375rem;
  }
}

@media (max-width: 64rem) {
  select, textarea, input[type=text], input[type=password],
  input[type=datetime], input[type=datetime-local],
  input[type=date], input[type=month], input[type=time],
  input[type=week], input[type=number], input[type=email],
  input[type=url], input[type=tel] {
    height: 2.375rem;
  }
}

@media (max-width: 48.0625rem) {
  select, textarea, input[type=text], input[type=password],
  input[type=datetime], input[type=datetime-local],
  input[type=date], input[type=month], input[type=time],
  input[type=week], input[type=number], input[type=email],
  input[type=url], input[type=tel] {
    height: 2.25rem;
    padding: 0.3125rem 0.625rem;
    font-size: 0.9375rem;
    border-width: 0.0625rem;
  }
}

select::placeholder, textarea::placeholder, input[type=text]::placeholder, input[type=password]::placeholder,
input[type=datetime]::placeholder, input[type=datetime-local]::placeholder,
input[type=date]::placeholder, input[type=month]::placeholder, input[type=time]::placeholder,
input[type=week]::placeholder, input[type=number]::placeholder, input[type=email]::placeholder,
input[type=url]::placeholder, input[type=tel]::placeholder {
  color: #57accb;
  transition: all 300ms ease-in-out;
}

select:hover, select:focus, textarea:hover, textarea:focus, input[type=text]:hover, input[type=text]:focus, input[type=password]:hover, input[type=password]:focus,
input[type=datetime]:hover,
input[type=datetime]:focus, input[type=datetime-local]:hover, input[type=datetime-local]:focus,
input[type=date]:hover,
input[type=date]:focus, input[type=month]:hover, input[type=month]:focus, input[type=time]:hover, input[type=time]:focus,
input[type=week]:hover,
input[type=week]:focus, input[type=number]:hover, input[type=number]:focus, input[type=email]:hover, input[type=email]:focus,
input[type=url]:hover,
input[type=url]:focus, input[type=tel]:hover, input[type=tel]:focus {
  border: 0.0625rem solid #57accb;
}

@media (max-width: 48.0625rem) {
  select:hover, select:focus, textarea:hover, textarea:focus, input[type=text]:hover, input[type=text]:focus, input[type=password]:hover, input[type=password]:focus,
  input[type=datetime]:hover,
  input[type=datetime]:focus, input[type=datetime-local]:hover, input[type=datetime-local]:focus,
  input[type=date]:hover,
  input[type=date]:focus, input[type=month]:hover, input[type=month]:focus, input[type=time]:hover, input[type=time]:focus,
  input[type=week]:hover,
  input[type=week]:focus, input[type=number]:hover, input[type=number]:focus, input[type=email]:hover, input[type=email]:focus,
  input[type=url]:hover,
  input[type=url]:focus, input[type=tel]:hover, input[type=tel]:focus {
    border-width: 0.0625rem;
  }
}

label {
  color: #000;
  line-height: 1.6em;
}

h1, h2, h3, h4, h5, h6 {
  color: #000;
  font-weight: 400;
  line-height: 1.1;
  margin: 0.625rem 0 1.25rem;
}

.c-block__header h1, .c-block__header h2, .c-block__header h3, .c-block__header h4, .c-block__header h5, .c-block__header h6 {
  color: #24eac3;
}

h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong {
  font-weight: 600;
}

/* stylelint-disable selector-no-qualifying-type */
h1, .h1 {
  font-size: 1.875rem;
}

h1 .u-text--enlarge, h1.u-text--enlarge, .h1 .u-text--enlarge, .h1.u-text--enlarge {
  font-size: 2.25rem;
}

h1 .u-text--ensmall, h1.u-text--ensmall, .h1 .u-text--ensmall, .h1.u-text--ensmall {
  font-size: 1.75rem;
}

h2, .h2:not(.c-heading) {
  font-size: 1.75rem;
}

h2 .u-text--enlarge, h2.u-text--enlarge, .h2:not(.c-heading) .u-text--enlarge, .h2:not(.c-heading).u-text--enlarge {
  font-size: 2rem;
}

h2 .u-text--ensmall, h2.u-text--ensmall, .h2:not(.c-heading) .u-text--ensmall, .h2:not(.c-heading).u-text--ensmall {
  font-size: 1.625rem;
}

h3, .h3 {
  font-size: 1.625rem;
}

h3 .u-text--enlarge, h3.u-text--enlarge, .h3 .u-text--enlarge, .h3.u-text--enlarge {
  font-size: 1.875rem;
}

h3 .u-text--ensmall, h3.u-text--ensmall, .h3 .u-text--ensmall, .h3.u-text--ensmall {
  font-size: 1.375rem;
}

h4, .h4 {
  font-size: 1.125rem;
}

h4 .u-text--enlarge, h4.u-text--enlarge, .h4 .u-text--enlarge, .h4.u-text--enlarge {
  font-size: 1.375rem;
}

h4 .u-text--ensmall, h4.u-text--ensmall, .h4 .u-text--ensmall, .h4.u-text--ensmall {
  font-size: 1rem;
}

h5, .h5 {
  font-size: 0.875rem;
}

h5 .u-text--enlarge, h5.u-text--enlarge, .h5 .u-text--enlarge, .h5.u-text--enlarge {
  font-size: 1rem;
}

h5 .u-text--ensmall, h5.u-text--ensmall, .h5 .u-text--ensmall, .h5.u-text--ensmall {
  font-size: 0.875rem;
}

h6, .h6 {
  font-size: 0.8125rem;
}

h6 .u-text--enlarge, h6.u-text--enlarge, .h6 .u-text--enlarge, .h6.u-text--enlarge {
  font-size: 0.9375rem;
}

h6 .u-text--ensmall, h6.u-text--ensmall, .h6 .u-text--ensmall, .h6.u-text--ensmall {
  font-size: 0.8125rem;
}

@media (max-width: 64rem) {
  h1, .h1 {
    font-size: 1.75rem;
  }
  h1 .u-text--enlarge, h1.u-text--enlarge, .h1 .u-text--enlarge, .h1.u-text--enlarge {
    font-size: 2rem;
  }
  h1 .u-text--ensmall, h1.u-text--ensmall, .h1 .u-text--ensmall, .h1.u-text--ensmall {
    font-size: 1.5rem;
  }
  h2, .h2 {
    font-size: 1.5rem;
  }
  h2 .u-text--enlarge, h2.u-text--enlarge, .h2 .u-text--enlarge, .h2.u-text--enlarge {
    font-size: 1.75rem;
  }
  h2 .u-text--ensmall, h2.u-text--ensmall, .h2 .u-text--ensmall, .h2.u-text--ensmall {
    font-size: 1.25rem;
  }
  h3, .h3 {
    font-size: 1.375rem;
  }
  h3 .u-text--enlarge, h3.u-text--enlarge, .h3 .u-text--enlarge, .h3.u-text--enlarge {
    font-size: 1.625rem;
  }
  h3 .u-text--ensmall, h3.u-text--ensmall, .h3 .u-text--ensmall, .h3.u-text--ensmall {
    font-size: 1.125rem;
  }
}

@media (max-width: 48.0625rem) {
  h1, .h1 {
    font-size: 1.5rem;
  }
  h1 .u-text--enlarge, h1.u-text--enlarge, .h1 .u-text--enlarge, .h1.u-text--enlarge {
    font-size: 1.75rem;
  }
  h1 .u-text--ensmall, h1.u-text--ensmall, .h1 .u-text--ensmall, .h1.u-text--ensmall {
    font-size: 1.25rem;
  }
  h2, .h2 {
    font-size: 1.375rem;
  }
  h2 .u-text--enlarge, h2.u-text--enlarge, .h2 .u-text--enlarge, .h2.u-text--enlarge {
    font-size: 1.625rem;
  }
  h2 .u-text--ensmall, h2.u-text--ensmall, .h2 .u-text--ensmall, .h2.u-text--ensmall {
    font-size: 1.125rem;
  }
  h3, .h3 {
    font-size: 1.25rem;
  }
  h3 .u-text--enlarge, h3.u-text--enlarge, .h3 .u-text--enlarge, .h3.u-text--enlarge {
    font-size: 1.5rem;
  }
  h3 .u-text--ensmall, h3.u-text--ensmall, .h3 .u-text--ensmall, .h3.u-text--ensmall {
    font-size: 1rem;
  }
  h4, .h4 {
    font-size: 0.9375rem;
  }
  h4 .u-text--enlarge, h4.u-text--enlarge, .h4 .u-text--enlarge, .h4.u-text--enlarge {
    font-size: 1.125rem;
  }
  h4 .u-text--ensmall, h4.u-text--ensmall, .h4 .u-text--ensmall, .h4.u-text--ensmall {
    font-size: 0.875rem;
  }
}

hr {
  border: 0.0625rem solid #dddddd;
}

html {
  background: #fff;
  color: #000;
  font-size: 1rem;
  line-height: 1.05;
}

a {
  color: #57accb;
  text-decoration: none;
}

a:hover {
  color: #7ebfd7;
  text-decoration: underline;
}

.c-x5__table {
  background-color: #fff;
}

.c-x5__table--shadow {
  box-shadow: 0 0 35px 5px rgba(0, 0, 0, 0.3);
}

.c-x5__table--border-grey,
.c-x5__table--header-bordered.c-x5__table--border-grey th,
.c-x5__table-rows--bordered.c-x5__table--border-grey td,
.c-x5__table-cols--bordered.c-x5__table--border-grey th,
.c-x5__table-cols--bordered.c-x5__table--border-grey td,
.c-x5__table-rows--striped-bordered.c-x5__table--border-grey td,
.c-x5__table-rows--striped-bordered-reverse.c-x5__table--border-grey td,
.c-x5__table-cols--striped-bordered.c-x5__table--border-grey th,
.c-x5__table-cols--striped-bordered.c-x5__table--border-grey td,
.c-x5__table-cols--striped-bordered-reverse.c-x5__table--border-grey th,
.c-x5__table-cols--striped-bordered-reverse.c-x5__table--border-grey td {
  border-color: #bfbfbf;
}

.c-x5__table--border-black,
.c-x5__table--header-bordered.c-x5__table--border-black th,
.c-x5__table-rows--bordered.c-x5__table--border-black td,
.c-x5__table-cols--bordered.c-x5__table--border-black th,
.c-x5__table-cols--bordered.c-x5__table--border-black td,
.c-x5__table-rows--striped-bordered.c-x5__table--border-black td,
.c-x5__table-rows--striped-bordered-reverse.c-x5__table--border-black td,
.c-x5__table-cols--striped-bordered.c-x5__table--border-black th,
.c-x5__table-cols--striped-bordered.c-x5__table--border-black td,
.c-x5__table-cols--striped-bordered-reverse.c-x5__table--border-black th,
.c-x5__table-cols--striped-bordered-reverse.c-x5__table--border-black td {
  border-color: #000;
}

.c-x5__table thead {
  background-color: #000;
  color: #fff;
}

.c-x5__table--header-grey thead {
  background-color: #dddddd;
}

.c-x5__table--header-white thead {
  background-color: #fff;
  color: #000;
}

.c-x5__table td {
  font-size: 0.875rem;
}

.c-x5__table th, .c-x5__table td {
  border-color: inherit;
}

.c-x5__table--header-bordered th {
  border-bottom: solid 0.0625rem;
}

.c-x5__table-rows--bordered td,
.c-x5__table-rows--striped-bordered td,
.c-x5__table-rows--striped-bordered-reverse td {
  border-bottom: solid 0.0625rem;
}

.c-x5__table-rows--bordered tr:last-child() td,
.c-x5__table-rows--striped-bordered tr:last-child() td,
.c-x5__table-rows--striped-bordered-reverse tr:last-child() td {
  border-bottom: none;
}

.c-x5__table-cols--bordered th,
.c-x5__table-cols--bordered td,
.c-x5__table-cols--striped-bordered th,
.c-x5__table-cols--striped-bordered td,
.c-x5__table-cols--striped-bordered-reverse th,
.c-x5__table-cols--striped-bordered-reverse td {
  border-right: solid 0.0625rem;
}

.c-x5__table-cols--bordered th:last-child(),
.c-x5__table-cols--bordered td:last-child(),
.c-x5__table-cols--striped-bordered th:last-child(),
.c-x5__table-cols--striped-bordered td:last-child(),
.c-x5__table-cols--striped-bordered-reverse th:last-child(),
.c-x5__table-cols--striped-bordered-reverse td:last-child() {
  border-right: none;
}

.c-x5__table-rows--striped tbody tr:nth-child(odd) td,
.c-x5__table-rows--striped-bordered tbody tr:nth-child(odd) td,
.c-x5__table-rows--striped-reverse tbody tr:nth-child(even) td,
.c-x5__table-rows--striped-bordered-reverse tbody tr:nth-child(even) td {
  background-color: #eeeeee;
}

.c-x5__table-cols--striped th:nth-child(odd),
.c-x5__table-cols--striped td:nth-child(odd),
.c-x5__table-cols--striped-reverse th:nth-child(even),
.c-x5__table-cols--striped-reverse td:nth-child(even),
.c-x5__table-cols--striped-bordered th:nth-child(odd),
.c-x5__table-cols--striped-bordered td:nth-child(odd),
.c-x5__table-cols--striped-bordered-reverse th:nth-child(even),
.c-x5__table-cols--striped-bordered-reverse td:nth-child(even) {
  background-color: #eeeeee;
}

.c-x5__table--border-grey td,
.c-x5__table--border-grey th {
  border-color: #bfbfbf;
}

@media (min-width: 48.0625rem) {
  .c-x5__table--striped tbody tr:nth-child(even),
  .c-x5__table--striped-reverse tbody tr:nth-child(odd),
  .c-x5__table--striped-cols td:nth-child(even),
  .c-x5__table--striped-cols.c-x5__table--header-white th:nth-child(even),
  .c-x5__table--striped-cols-reverse td:nth-child(odd),
  .c-x5__table--striped-cols-reverse.c-x5__table--header-white th:nth-child(odd) {
    background-color: #eeeeee;
  }
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
.o-layout {
  max-width: 85.375rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}

.o-layout .o-layout {
  margin-right: -0.78125rem;
  margin-left: -0.78125rem;
}

@media print, screen and (min-width: 48.0625em) {
  .o-layout .o-layout {
    margin-right: -0.78125rem;
    margin-left: -0.78125rem;
  }
}

@media print, screen and (min-width: 64em) {
  .o-layout .o-layout {
    margin-right: -0.78125rem;
    margin-left: -0.78125rem;
  }
}

@media print, screen and (min-width: 72em) {
  .o-layout .o-layout {
    margin-right: -0.78125rem;
    margin-left: -0.78125rem;
  }
}

.o-layout:not(.is-expanded) .o-layout {
  max-width: none;
}

.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 85.375rem;
  position: relative;
}

body {
  border: 0.3125rem solid transparent;
  box-sizing: border-box;
}

@media (min-width: 27.5rem) {
  body {
    border: 0.9375rem solid transparent;
  }
}

@media (min-width: 64rem) {
  body {
    border: 1.25rem solid transparent;
  }
}

@media (min-width: 85.4375rem) {
  body {
    border: 1.875rem solid transparent;
  }
}

.addtocalendar {
  max-width: 21.875rem;
  width: 100%;
}

.addtocalendar:hover .atcb-link {
  color: #57accb;
}

.atcb-list {
  background-color: #57accb;
  border-radius: 0 0 0.1875rem 0.1875rem;
  box-shadow: 0 0.3125rem 1.25rem #bfbfbf;
}

.atcb-link {
  color: #fff;
  border-radius: 0.1875rem;
  border: 1px solid #57accb;
}

.atcb-link:focus, .atcb-link:hover {
  color: #57accb;
}

.atcb-item-link {
  color: #fff;
}

.atcb-item-link:hover {
  color: #fff;
}

.c-book a:hover {
  color: #57accb;
}

.c-book__inner {
  height: 100%;
  border: 1px solid #eeeeee;
}

@media (max-width: 27.5rem) {
  .c-book__inner {
    max-width: 21.875rem;
  }
}

.c-book__cover {
  width: 50%;
  max-width: 11.875rem;
}

@media (max-width: 27.5rem) {
  .c-book__cover {
    width: 100%;
  }
}

.c-book__single .c-book__cover {
  width: 100%;
  max-width: 21.875rem;
}

.c-book__title {
  color: #555555;
}

.c-book__authors h6 {
  color: #dddddd;
}

.c-book__authors a {
  color: #bfbfbf;
}

.c-book__publisher,
.c-book__isbn {
  font-size: 1rem;
}

.c-books__orderby label {
  color: #bfbfbf;
}

.c-breadcrumb__item:not(:last-child)::after {
  content: '';
  background-color: #bfbfbf;
}

.c-breadcrumb__link,
.c-breadcrumb__item a {
  color: #555555;
}

.c-breadcrumb__link:hover,
.c-breadcrumb__item a:hover {
  color: #555555;
}

.c-btn {
  font-size: 1.125rem;
  font-weight: 400;
  white-space: nowrap;
  padding: 0.75rem 2.8125rem;
}

.c-btn .c-icon {
  width: 1.625rem;
  height: 1.125rem;
}

@media (max-width: 48.0625rem) {
  .c-btn {
    padding: 0.375rem 1.875rem;
    font-size: 1.0625rem;
  }
}

.c-btn.home-hide {
  font-size: 1.5rem;
}

@media (max-width: 85.4375rem) {
  .c-btn.home-hide {
    font-size: 1.25rem;
  }
}

@media (max-width: 72rem) {
  .c-btn.home-hide {
    font-size: 1.125rem;
  }
}

.c-btn--primary {
  background-color: transparent;
  color: #24eac3;
  border: 0.1875rem solid #24eac3;
}

.c-btn--primary:hover, .c-btn--primary:active, .c-btn--primary:focus {
  background-color: #24eac3;
  color: #fff;
  border: 0.1875rem solid #24eac3;
}

.c-btn--primary-filled {
  background-color: #24eac3;
  color: #fff;
  border: 0.1875rem solid #24eac3;
}

.c-btn--primary-filled:hover, .c-btn--primary-filled:active, .c-btn--primary-filled:focus {
  background-color: transparent;
  color: #24eac3;
  border: 0.1875rem solid #24eac3;
}

.c-btn--secondary {
  background-color: transparent;
  color: #28bdaa;
  border: 0.1875rem solid #28bdaa;
}

.c-btn--secondary:hover, .c-btn--secondary:active, .c-btn--secondary:focus {
  background-color: #28bdaa;
  color: #fff;
  border: 0.1875rem solid #28bdaa;
}

.c-btn--secondary-filled {
  background-color: #28bdaa;
  color: #fff;
  border: 0.1875rem solid #28bdaa;
}

.c-btn--secondary-filled:hover, .c-btn--secondary-filled:active, .c-btn--secondary-filled:focus {
  background-color: transparent;
  color: #28bdaa;
  border: 0.1875rem solid #28bdaa;
}

.c-btn--link {
  background-color: transparent;
  color: #57accb;
  border: 0.1875rem solid #57accb;
}

.c-btn--link:hover, .c-btn--link:active, .c-btn--link:focus {
  background-color: #57accb;
  color: #fff;
  border: 0.1875rem solid #57accb;
}

.c-btn--link-filled {
  background-color: #57accb;
  color: #fff;
  border: 0.1875rem solid #57accb;
}

.c-btn--link-filled:hover, .c-btn--link-filled:active, .c-btn--link-filled:focus {
  background-color: transparent;
  color: #57accb;
  border: 0.1875rem solid #57accb;
}

.c-btn--link-underline {
  background-color: transparent;
  color: #555555;
  border: none;
  padding-left: 0;
  padding-right: 0;
  border: none;
  border-bottom: 0.1875rem solid #57accb;
}

.c-btn--link-underline:hover, .c-btn--link-underline:active, .c-btn--link-underline:focus {
  background-color: transparent;
  color: #7ebfd7;
  border: none;
}

.c-btn--link-underline:hover, .c-btn--link-underline:active, .c-btn--link-underline:focus {
  text-decoration: none !important;
  border-bottom: 0.1875rem solid #7ebfd7;
}

.c-listing__item .c-btn--link-underline {
  padding: 0;
  border: none;
  font-size: 1rem;
  color: #24eac3;
}

.c-listing__item .c-btn--link-underline:hover, .c-listing__item .c-btn--link-underline:focus {
  color: #7ebfd7;
}

.c-btn--primary-light {
  background-color: #24eac3;
  color: #fff;
  border: 0.1875rem solid #24eac3;
}

.c-btn--primary-light:hover, .c-btn--primary-light:active, .c-btn--primary-light:focus {
  background-color: #28bdaa;
  color: #fff;
  border: 0.1875rem solid #28bdaa;
}

.c-btn--secondary-light {
  background-color: #57accb;
  color: #fff;
  border: 0.1875rem solid #57accb;
}

.c-btn--secondary-light:hover, .c-btn--secondary-light:active, .c-btn--secondary-light:focus {
  background-color: #7ebfd7;
  color: #fff;
  border: 0.1875rem solid #7ebfd7;
}

.c-btn--arrow-primary,
.c-btn--arrow-secondary {
  padding: 0.1875rem 0.625rem;
  border: none;
}

.c-btn--arrow-primary {
  color: #555555;
}

.c-btn--arrow-primary .c-icon {
  fill: #57accb;
}

.c-btn--arrow-primary:hover, .c-btn--arrow-primary:active, .c-btn--arrow-primary:focus {
  color: #57accb;
}

.c-btn--arrow-secondary {
  color: #555555;
}

.c-btn--arrow-secondary .c-icon {
  fill: #24eac3;
}

.c-btn--arrow-secondary:hover, .c-btn--arrow-secondary:active, .c-btn--arrow-secondary:focus {
  color: #24eac3;
}

.c-calendar__pager {
  background-color: #dddddd;
  border-radius: 0.1875rem;
  height: 1.875rem;
}

.c-calendar__pager-prev,
.c-calendar__pager-next {
  background-color: #fff;
  border-radius: 0 0.1875rem 0.1875rem 0;
  border: 1px solid #bfbfbf;
  height: 2.25rem;
}

.c-calendar__pager-prev:hover,
.c-calendar__pager-next:hover {
  border-color: #999999;
}

.c-calendar__pager-prev {
  border-radius: 0.1875rem 0 0 0.1875rem;
}

.c-calendar__pager-link {
  font-size: 0.875rem;
  line-height: 2.25rem;
  color: #777777;
}

.c-calendar__pager-link:hover {
  color: #777777;
}

.c-calendar__pager-link--inactive {
  color: #dddddd;
}

.c-calendar__pager-link--inactive:hover {
  color: #dddddd;
}

.c-calendar__header {
  background-color: #eeeeee;
  color: #999999;
  border: 1px solid #bfbfbf;
}

.c-calendar__date-box,
.c-calendar__single-day {
  width: 14%;
  border: 1px solid #bfbfbf;
  color: #999999;
}

.c-calendar__date-box--past,
.c-calendar__date-box--future,
.c-calendar__single-day--past,
.c-calendar__single-day--future {
  background-color: #f4f4f4;
  color: #dddddd;
}

.c-calendar__date-box--today {
  border: 0.125rem solid #24eac3;
  border-bottom-width: 0;
}

.c-calendar__single-day--today {
  border: 0.125rem solid #24eac3;
  border-top-width: 0;
}

.c-calendar__single-day .c-calendar__inner {
  height: 9.375rem;
  line-height: 2.75rem;
}

@media (max-width: 80rem) {
  .c-calendar__single-day .c-calendar__inner {
    height: 7.5rem;
  }
}

.c-calendar__single-day .c-calendar__inner--full {
  height: 12.8125rem;
  font-size: 0.8125rem;
}

@media (max-width: 80rem) {
  .c-calendar__single-day .c-calendar__inner--full {
    height: 10rem;
  }
}

.c-calendar__event-title a:hover {
  color: #24eac3;
}

.c-calendar__list-item {
  border-bottom: 1px solid #bfbfbf;
}

.c-calendar__list-item--today {
  background-color: #dddddd;
}

.c-card {
  box-shadow: 0 0 0 1px #eeeeee inset;
}

.c-newsletters-page .c-card {
  max-width: 25.625rem;
}

.c-card--primary {
  box-shadow: 0 0 0 4px #24eac3 inset;
}

.c-courses__meta {
  border-bottom: 0.0625rem solid #777777;
  color: #777777;
}

.c-courses__teaser {
  color: #555555;
}

.c-courses__filter {
  border: 0.125rem solid #dddddd;
}

.c-courses__filter-reset {
  color: #555555;
}

.c-courses__filter-reset:hover {
  color: #555555;
}

.c-courses__item {
  border-bottom: 0.125rem solid #dddddd;
}

.flatpickr-prev-month:hover svg, .flatpickr-next-month:hover svg {
  fill: #24eac3 !important;
}

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  background: #eeeeee;
  border-color: #eeeeee;
}

.flatpickr-day.today {
  border-color: #24eac3 !important;
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  border-color: #24eac3 !important;
  background: #24eac3 !important;
  color: #fff !important;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: #24eac3;
  border-color: #24eac3;
}

.flatpickr-day.selected.startRange + .endRange,
.flatpickr-day.startRange.startRange + .endRange,
.flatpickr-day.endRange.startRange + .endRange {
  box-shadow: -10px 0 0 #24eac3;
}

.flatpickr-day.startRange, .flatpickr-day.prevMonthDay.startRange, .flatpickr-day.nextMonthDay.startRange, .flatpickr-day.endRange, .flatpickr-day.prevMonthDay.endRange, .flatpickr-day.nextMonthDay.endRange {
  border-color: #24eac3;
}

.flatpickr-time input {
  border: solid 0.0625rem #eeeeee !important;
}

.c-entry__header {
  background-color: #eeeeee;
}

.c-entry__title {
  font-size: 1.625rem;
}

@media (min-width: 48.0625rem) {
  .c-entry__title {
    font-size: 1.875rem;
  }
}

@media (min-width: 64rem) {
  .c-entry__title {
    font-size: 2.25rem;
  }
}

.c-entry__meta {
  font-size: 1.125rem;
}

.c-entry__meta-item {
  border-left: 0.125rem solid #000;
}

.c-entry__meta-item:first-child {
  border-left: none;
}

.c-entry__author {
  font-size: 1.125rem;
}

.c-entry__content {
  font-size: 1.125rem;
}

.c-entry__lead {
  font-size: 1.5rem;
  line-height: 2.625rem;
}

.c-events-calendar {
  width: 100%;
}

@media (min-width: 64rem) {
  .c-events-calendar--size-75 {
    width: 75%;
  }
}

@media (min-width: 48.0625rem) {
  .c-events-calendar--size-66 {
    width: 75%;
  }
}

@media (min-width: 64rem) {
  .c-events-calendar--size-66 {
    width: 66%;
  }
}

@media (min-width: 48.0625rem) {
  .c-events-calendar--size-50 {
    width: 75%;
  }
}

@media (min-width: 64rem) {
  .c-events-calendar--size-50 {
    width: 66%;
  }
}

@media (min-width: 72rem) {
  .c-events-calendar--size-50 {
    width: 50%;
  }
}

.c-events-calendar .fc-header-toolbar h2 {
  top: 0.1875rem;
  font-size: 1.5rem;
}

@media (max-width: 72rem) {
  .c-events-calendar .fc-header-toolbar h2 {
    top: 0.3125rem;
    font-size: 1.125rem;
  }
}

@media (max-width: 64rem) {
  .c-events-calendar .fc-header-toolbar h2 {
    font-size: 1rem;
  }
}

.c-events-calendar .fc-header-toolbar .fc-button {
  background-color: #24eac3;
  color: #fff;
}

@media (max-width: 72rem) {
  .c-events-calendar .fc-header-toolbar .fc-button {
    font-size: 0.875rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-events-calendar .fc-header-toolbar .fc-button {
    font-size: 0.8125rem;
  }
}

.c-events-calendar .fc-header-toolbar .fc-button:hover {
  background-color: #7ebfd7;
}

.c-events-calendar .fc-header-toolbar .fc-state-disabled,
.c-events-calendar .fc-header-toolbar .fc-today-button {
  background-color: #57accb;
}

.c-events-calendar .fc-header-toolbar .fc-state-disabled:hover,
.c-events-calendar .fc-header-toolbar .fc-today-button:hover {
  background-color: #7ebfd7;
}

@media (max-width: 48.0625rem) {
  .c-events-calendar .fc-day-header {
    font-size: 0.8125rem;
  }
}

.c-events-calendar td.fc-today {
  /* stylelint-disable-line selector-no-qualifying-type */
  background-color: #eeeeee;
}

.c-events-calendar .fc-event-container .fc-event {
  padding: 0.0625rem 0.3125rem;
  background-color: #24eac3;
  transition: all 300ms ease-in-out;
}

@media (max-width: 27.5rem) {
  .c-events-calendar .fc-event-container .fc-event {
    padding: 0.0625rem 0.125rem;
  }
}

.c-events-calendar .fc-event-container .fc-event:hover {
  background-color: #28bdaa;
}

.c-events-calendar .fc-event-container .fc-title {
  display: block;
  min-height: 1.125rem;
}

.c-footer {
  background-color: #0d091a;
  color: #fff;
}

@media (max-width: 64rem) {
  .c-footer__column {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.c-footer__column-header h3 {
  border-color: #24eac3;
}

.c-footer .c-footer__inner {
  max-width: 366px;
}

@media (min-width: 64rem) {
  .c-footer .c-footer__inner {
    max-width: 100%;
  }
}

.c-footer .c-social-media__link {
  border-color: #fff;
}

.c-footer .c-social-media__link .c-social-media__icon {
  fill: #fff;
}

.c-footer .js-google-map {
  max-width: 20.625rem;
}

.c-footer__copyright {
  background-color: #fffcfd;
  font-size: 1.0625rem;
  border-top: 1px solid #eeeeee;
}

.c-footer__copyright .c-footer__logo {
  max-width: 10rem;
}

.c-form__control:hover:not([type=file]), .c-form__control:focus:not([type=file]) {
  border: 0.0625rem solid #57accb;
  background-color: #fff;
  color: #555555;
}

.c-form__control:hover:not([type=file])::placeholder, .c-form__control:focus:not([type=file])::placeholder {
  color: #555555;
}

.c-form-group label span,
.c-form-group legend span {
  color: #cc0000;
}

.c-form-group .c-field__label {
  font-size: 1.125rem;
  font-weight: 600;
}

@media (max-width: 48.0625rem) {
  .c-form-group .c-field__label {
    font-size: 1rem;
  }
}

.c-form-group .c-field__label .c-icon {
  stroke: #24eac3;
  fill: #24eac3;
}

.c-form-group legend {
  color: #24eac3;
}

.c-form-group fieldset {
  border: solid 1px #d8dadd;
}

.c-form__control.c-form__textarea {
  height: 12.5rem !important;
}

.c-field__error {
  font-size: 0.875rem;
  color: #cc0000;
}

@media (max-width: 48.0625rem) {
  .c-field__error {
    font-size: 0.8125rem;
  }
}

.c-field__message {
  font-size: 1rem;
}

.c-field__message.show {
  width: 80%;
  color: #fff;
}

.c-field__message.error {
  background-color: #cc0000;
}

.c-field__message.success {
  background-color: #1cca98;
}

@media (max-width: 72rem) {
  .c-field__message {
    font-size: 1rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-field__message {
    font-size: 0.9375rem;
  }
}

.c-newsletter__form .o-layout {
  width: calc(100% + 26px);
}

.c-newsletter__form .c-form__input,
.c-newsletter__form .c-btn {
  width: 100%;
  height: 4.5rem;
  font-weight: 500;
}

@media (max-width: 85.4375rem) {
  .c-newsletter__form .c-form__input,
  .c-newsletter__form .c-btn {
    height: 4.125rem;
  }
}

@media (max-width: 64rem) {
  .c-newsletter__form .c-form__input,
  .c-newsletter__form .c-btn {
    height: 3.875rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-newsletter__form .c-form__input,
  .c-newsletter__form .c-btn {
    height: 3.125rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-newsletter__form .c-btn {
    font-size: 1.125rem;
  }
}

.c-newsletter__form .c-form__input {
  border-color: #fff;
  color: #555555;
}

.c-newsletter__form .c-form__input:hover, .c-newsletter__form .c-form__input:focus {
  border-color: #24eac3;
}

.c-newsletter__form .c-form__input::placeholder {
  color: #555555;
}

.c-galleries--list {
  padding-top: 0.625rem;
}

@media (max-width: 64rem) {
  .c-galleries--list {
    padding-top: 0;
  }
}

.c-galleries--list li {
  border-bottom: 1px solid #eeeeee;
}

.c-gallery__title {
  font-size: 1.125rem;
}

@media (max-width: 64rem) {
  .c-gallery__title {
    font-size: 1rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-gallery__title {
    font-size: 0.875rem;
  }
}

.c-gallery__title a:hover {
  color: #57accb;
}

.c-galleries--list .c-gallery__title {
  font-size: 1rem;
}

.c-gallery__single-item-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}

.c-gallery__single-item .icon {
  width: 1.875rem;
  height: 1.875rem;
  fill: #fff;
}

.c-gallery__single--cols-1 .c-gallery__single-item .icon {
  width: 2.5rem;
  height: 2.5rem;
}

.c-gallery__single--cols-2 .c-gallery__single-item .icon {
  width: 2.5rem;
  height: 2.5rem;
}

.c-gallery__single--cols-3 .c-gallery__single-item .icon {
  width: 2.5rem;
  height: 2.5rem;
}

.c-gallery__entry {
  padding-top: 1.25rem;
  padding-bottom: 0.625rem;
}

.c-galleries--thumbs .c-gallery__inner {
  max-width: 33.125rem;
  margin-bottom: 1.25rem;
  border: solid 1px #eeeeee;
}

.c-header__inner {
  width: 100%;
  height: 6.875rem;
}

@media (max-width: 64rem) {
  .c-header__inner {
    height: 5rem;
  }
}

.c-header--shrink .c-header__inner {
  height: 5.625rem;
}

@media (max-width: 64rem) {
  .c-header--shrink .c-header__inner {
    height: 3.75rem;
  }
}

.c-header--container .c-header__inner {
  max-width: 85.375rem;
}

.c-header--floating .c-header__inner {
  height: 5.625rem;
}

@media (max-width: 64rem) {
  .c-header--floating .c-header__inner {
    height: 3.75rem;
  }
}

.c-header--floating:not(.c-header--full) .c-header__container {
  max-width: 83.75rem;
}

.body__fixed-header--floating .c-header--floating:not(.c-header--full) .c-header__container {
  max-width: none;
}

@media (min-width: 64rem) {
  .c-header--floating, .c-header--fixed {
    border: 1.25rem solid transparent;
  }
}

@media (min-width: 85.4375rem) {
  .c-header--floating, .c-header--fixed {
    border: 1.875rem solid transparent;
  }
}

.c-header--floating.c-header--shrink .c-header__container {
  max-width: none;
}

.c-header--static.c-header--image .c-header__inner {
  height: 13.125rem;
  align-items: flex-start;
}

@media (max-width: 85.4375rem) {
  .c-header--static.c-header--image .c-header__inner {
    height: 11.25rem;
  }
}

@media (max-width: 64rem) {
  .c-header--static.c-header--image .c-header__inner {
    height: 7.5rem;
  }
}

.c-header__logo {
  width: 25%;
  height: auto;
}

.c-header__logo a {
  height: 100%;
  width: 100%;
  max-width: 18.75rem;
}

@media (max-width: 85.4375rem) {
  .c-header__logo {
    width: 20%;
  }
}

@media (max-width: 80rem) {
  .c-header__logo {
    width: 17%;
  }
}

@media (max-width: 64rem) {
  .c-header__logo {
    max-width: 11.875rem;
    width: 100%;
  }
}

.c-header__nav {
  width: 75%;
}

@media (max-width: 85.4375rem) {
  .c-header__nav {
    width: 80%;
  }
}

@media (max-width: 80rem) {
  .c-header__nav {
    width: 83%;
  }
}

@media (max-width: 64rem) {
  .c-header__nav {
    width: auto;
  }
}

.c-search {
  background-color: rgba(191, 191, 191, 0.95);
}

.c-search__form {
  max-width: 60%;
  width: 95%;
  border: 1.25rem solid #24eac3;
}

@media (max-width: 64rem) {
  .c-search__form {
    border-width: 0.625rem;
    max-width: 31.25rem;
  }
}

.c-search__form:hover {
  border-color: #28bdaa !important;
}

.c-search [type=text].c-search__input {
  height: 4.375rem;
}

@media (max-width: 64rem) {
  .c-search [type=text].c-search__input {
    height: 3.125rem;
  }
}

.c-search__btn {
  height: 4.375rem;
}

@media (max-width: 64rem) {
  .c-search__btn {
    height: 3.125rem;
  }
}

.c-search__close {
  color: #24eac3;
}

.c-search__close:hover {
  color: #28bdaa !important;
  text-decoration: none !important;
}

.c-heading {
  border-bottom: 0.25rem solid #24eac3;
  color: #000;
}

.c-heading--secondary {
  color: #24eac3;
}

.c-page__title {
  margin-top: 1.875rem;
  color: #24eac3;
}

.c-hero--imageless {
  background-color: #dddddd;
}

/* stylelint-disable selector-max-id */
#baguetteBox-overlay {
  background-color: rgba(150, 238, 234, 0.9) !important;
}

#baguetteBox-overlay .full-image figcaption {
  color: #eee;
}

#baguetteBox-overlay .baguetteBox-button {
  background-color: rgba(255, 255, 255, 0) !important;
}

#baguetteBox-overlay .baguetteBox-button svg polyline,
#baguetteBox-overlay .baguetteBox-button svg g {
  stroke: #24eac3 !important;
}

/* stylelint-enable */
.c-link {
  border-bottom: 0.25rem solid #24eac3;
  color: #000;
}

.c-link:hover {
  color: #57accb;
  border-color: #57accb;
}

.c-listing__title {
  margin-bottom: 1.25rem;
}

@media (max-width: 64rem) {
  .c-listing__title {
    margin-bottom: 0.9375rem;
  }
}

.c-listing__title--right {
  margin-top: 0;
}

.c-listing__item {
  border-bottom: 0.125rem solid #dddddd;
}

.c-listing__link {
  color: #24eac3;
  text-decoration: none;
  transition: all 300ms ease-in-out;
}

.c-listing__link:hover {
  color: #57accb;
}

.c-main-nav__items {
  padding: 3.125rem 0 0 0;
}

.c-header--static.c-header--image .c-main-nav__items {
  padding-top: 7.6875rem;
}

@media (max-width: 85.4375rem) {
  .c-header--static.c-header--image .c-main-nav__items {
    padding-top: 5.8125rem;
  }
}

.c-main-nav__item.menu-item-has-children:after, .c-main-nav__item.menu-item-has-children:before {
  border-left: 0.3125rem solid transparent;
  border-right: 0.3125rem solid transparent;
  border-top: 0.4375rem solid;
}

.c-main-nav__item.menu-item-has-children:before {
  bottom: -0.5625rem;
  border-top-color: transparent;
}

.c-main-nav__link {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1.25rem;
  text-transform: uppercase;
  text-decoration: none !important;
}

.c-header--static.c-header--image .c-main-nav__item--transparent .c-main-nav__link,
.c-header--static.c-header--none .c-main-nav__item--transparent .c-main-nav__link,
.c-header--none .c-main-nav__item--transparent .c-main-nav__link {
  padding-top: 1.9375rem;
  padding-bottom: 1.9375rem;
}

.c-main-nav__sublink {
  text-transform: uppercase;
  text-decoration: none !important;
}

.c-main-nav__search-trigger {
  margin-left: 0.9375rem;
}

@media (min-width: 80rem) {
  .c-main-nav__search-trigger {
    margin-left: 3.4375rem;
  }
}

.c-member__inner {
  text-align: left;
  border-bottom: none;
}

.c-member__image img {
  max-width: 9.375rem;
  height: auto;
}

.c-member__title {
  background-color: transparent;
  font-size: 1rem;
  margin-bottom: 0;
}

.c-member__link {
  padding: 0.625rem 0 0;
  color: #57accb;
}

.c-member__link:hover {
  color: #7ebfd7;
}

.c-member__content {
  padding-left: 0;
  padding-right: 0;
}

.c-member__email:before,
.c-member__dept-pos.notempty:after {
  display: block;
  margin: 0.625rem 0;
  content: '';
  width: 1.875rem;
  border-top: 0.25rem solid #0d091a;
}

.c-mobile-nav__trigger,
.c-main-nav__trigger,
.c-mobile-nav__close {
  border-top-color: #fff;
}

.c-mobile-nav__trigger::after, .c-mobile-nav__trigger::before,
.c-main-nav__trigger::after,
.c-main-nav__trigger::before,
.c-mobile-nav__close::after,
.c-mobile-nav__close::before {
  content: '';
  border-top-color: #fff;
}

.c-overlay--bordered:hover {
  border-color: #28bdaa;
}

.c-blocks--container {
  max-width: 85.375rem;
}

.c-blocks-main, .c-blocks-sidebar {
  min-height: 1px;
}

.c-blocks__main {
  width: 100%;
}

@media (min-width: 64rem) {
  .c-blocks--has-sidebar .c-blocks__main {
    width: 75%;
  }
}

@media (min-width: 64rem) {
  .c-blocks--has-sidebar .c-blocks__main--8 {
    width: 66.66667%;
  }
}

.c-blocks__sidebar {
  width: 100%;
  max-width: 21.875rem;
}

.c-blocks__sidebar--4 {
  max-width: 25rem;
}

@media (min-width: 64rem) {
  .c-blocks__sidebar {
    width: 25%;
  }
  .c-blocks__sidebar--4 {
    width: 33.33333%;
  }
}

.c-block {
  width: 100%;
}

.c-block__header {
  width: 100%;
}

.c-block__header span[style*="text-decoration"] {
  /* stylelint-disable-line string-quotes, selector-attribute-quotes */
  border-bottom: 0.25rem solid #57accb;
}

.c-block--clear {
  width: 100%;
}

.c-block--container {
  max-width: 85.375rem;
}

@media (min-width: 85.4375rem) {
  .c-block--container {
    max-width: 88.5rem;
  }
}

.c-block__inner {
  width: 100%;
  max-width: 85.375rem;
  min-height: 100px;
}

@media (min-width: 85.375rem) {
  .c-block__inner {
    max-width: 88.5rem;
    padding: 0.8125rem 1.4375rem;
  }
}

.c-block__inner--full {
  width: 100%;
  max-width: 128rem;
  padding: 0;
}

.c-block--type-slider .c-block__inner--full,
.c-block--type-gallery .c-block__inner--full,
.c-block--type-columns .c-block__inner--full {
  max-width: 100%;
}

@media (min-width: 72rem) {
  .c-block--w-75 .c-block__inner, .c-block__content--w-75 {
    max-width: 64.03125rem;
    width: 75%;
  }
}

@media (min-width: 85.4375rem) {
  .c-block--w-75 .c-block__inner, .c-block__content--w-75 {
    max-width: 66.375rem;
  }
}

@media (min-width: 72rem) {
  .c-block--w-66 .c-block__inner, .c-block__content--w-66 {
    max-width: 56.3475rem;
    width: 66%;
  }
}

@media (min-width: 85.4375rem) {
  .c-block--w-66 .c-block__inner, .c-block__content--w-66 {
    max-width: 58.41rem;
  }
}

.c-block--w-50 {
  width: 50%;
  max-width: 50%;
}

@media (max-width: 64rem) {
  .c-block--w-50 {
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 72rem) {
  .c-block__content--w-50 {
    max-width: 64.03125rem;
    width: 75%;
  }
}

@media (min-width: 80rem) {
  .c-block__content--w-50 {
    max-width: 42.6875rem;
    width: 50%;
  }
}

@media (min-width: 85.4375rem) {
  .c-block__content--w-50 {
    max-width: 44.25rem;
  }
}

div[class*='c-block--h-fixed-5'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  div[class*='c-block--h-fixed-5'] {
    min-height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  div[class*='c-block--h-fixed-5'] {
    min-height: 25rem !important;
  }
}

div[class*='c-block--h-fixed-6'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  div[class*='c-block--h-fixed-6'] {
    min-height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  div[class*='c-block--h-fixed-6'] {
    min-height: 25rem !important;
  }
}

div[class*='c-block--h-fixed-7'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 85.4375rem) {
  div[class*='c-block--h-fixed-7'] {
    min-height: 37.5rem !important;
  }
}

@media (max-width: 64rem) {
  div[class*='c-block--h-fixed-7'] {
    min-height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  div[class*='c-block--h-fixed-7'] {
    min-height: 25rem !important;
  }
}

div[class*='c-block--h-fixed-8'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 85.4375rem) {
  div[class*='c-block--h-fixed-8'] {
    min-height: 37.5rem !important;
  }
}

@media (max-width: 64rem) {
  div[class*='c-block--h-fixed-8'] {
    min-height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  div[class*='c-block--h-fixed-8'] {
    min-height: 25rem !important;
  }
}

.owl-nav .icon {
  fill: #fff;
}

.c-block__columns-arrows--dark .owl-nav .icon,
.c-block__slider-arrows--dark .owl-nav .icon {
  fill: #24eac3;
}

.owl-dots .owl-dot {
  background-color: #fafafa;
  border: 0.0625rem solid #57accb;
}

.c-block__slider-dots--dark .owl-dots .owl-dot,
.c-block__gallery-dots--dark .owl-dots .owl-dot,
.c-block__columns-dots--dark .owl-dots .owl-dot {
  background-color: #24eac3;
  border-color: #57accb;
}

.owl-dots .owl-dot.active {
  background-color: #24eac3;
  border-color: #57accb;
}

.c-block__slider-dots--dark .owl-dots .owl-dot.active,
.c-block__gallery-dots--dark .owl-dots .owl-dot.active,
.c-block__columns-dots--dark .owl-dots .owl-dot.active {
  background-color: #fff;
  border: 0.0625rem solid #57accb;
}

.c-block__slider-slide-caption-container {
  max-width: 85.375rem;
}

.c-block__slider-slide-caption-inner {
  padding: 2.5rem;
  border: solid 0.3125rem rgba(255, 255, 255, 0.3);
  font-size: 1.25rem;
}

.c-block__slider-slide-caption-inner p {
  padding-top: 0.625rem;
  line-height: 1.875rem;
}

@media (max-width: 64rem) {
  .c-block__slider-slide-caption-inner {
    padding: 1.5625rem;
    font-size: 1.125rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider-slide-caption-inner {
    padding: 0.9375rem;
    font-size: 1rem;
  }
  .c-block__slider-slide-caption-inner p {
    line-height: 1.5rem;
  }
}

.c-block__slider--full .c-block__slider-slide-caption-inner {
  max-width: 83.75rem;
}

.c-block__slider-slide-caption--50 {
  width: 100%;
}

@media (min-width: 48.0625rem) {
  .c-block__slider-slide-caption--50 {
    width: 75%;
  }
}

@media (min-width: 64rem) {
  .c-block__slider-slide-caption--50 {
    width: 50%;
  }
  .c-blocks--has-sidebar .c-block__slider-slide-caption--50 {
    width: 75%;
  }
}

.c-block__slider-slide-caption--75 {
  width: 100%;
}

@media (min-width: 48.0625rem) {
  .c-block__slider-slide-caption--75 {
    width: 75%;
  }
}

.c-slider__donate {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  max-width: 88.5rem;
  width: 100%;
  margin: 0 auto;
  text-align: right;
}

@media (max-width: 64rem) {
  .c-slider__donate {
    text-align: center;
  }
}

.c-slider__donate .c-btn {
  padding: 1.5625rem 2.5rem;
  margin: 0 0.625rem 0 0;
  font-size: 2.25rem;
  background-color: rgba(36, 234, 196, 0.6);
  border: none;
}

.c-slider__donate .c-btn:hover {
  background-color: #24eac4;
}

@media (max-width: 85.4375rem) {
  .c-slider__donate .c-btn {
    padding: 1.25rem 1.875rem;
    font-size: 1.75rem;
  }
}

@media (max-width: 64rem) {
  .c-slider__donate .c-btn {
    padding: 0.9375rem 1.25rem;
    font-size: 1.5rem;
  }
}

.c-block__columns-column-title {
  color: #24eac3;
}

.c-block__tabs-nav li {
  border: 0.0625rem solid #eeeeee;
}

.c-block__tabs--dark .c-block__tabs-nav li {
  border-color: #555555;
}

.c-block__tabs-nav .active a {
  background-color: #eeeeee;
}

.c-block__tabs--dark .c-block__tabs-nav .active a {
  background-color: #555555;
  color: #fff;
}

.c-block__tabs-nav a {
  background-color: #fafafa;
}

.c-block__tabs--dark .c-block__tabs-nav a {
  background-color: #bfbfbf;
  color: #fff;
}

.c-block__tabs-nav a:hover {
  background-color: #eeeeee;
}

.c-block__tabs--dark .c-block__tabs-nav a:hover {
  background-color: #555555;
  color: #fff;
}

.c-block__tabs-content {
  background-color: #eeeeee;
}

.c-block__tabs--dark .c-block__tabs-content {
  background-color: #555555;
  color: #fff;
}

.c-block__tabs-accordion-header a {
  border-bottom: 0.0625rem solid #dddddd;
  background-color: #fff;
}

.c-block__tabs--dark .c-block__tabs-accordion-header a {
  background-color: #bfbfbf;
  color: #fff;
}

.c-block__tabs-accordion-header .c-arrow-icon {
  fill: #555555;
}

.c-block__tabs--dark .c-block__tabs-accordion-header .c-arrow-icon {
  fill: #fafafa;
}

.c-block__tabs-accordion-header.active a {
  border-bottom: 1px solid #fff;
  background-color: #fff;
}

.c-block__tabs--dark .c-block__tabs-accordion-header.active a {
  background-color: #555555;
  color: #fff;
}

.c-block__tabs-accordion-content {
  background-color: #eeeeee;
}

.c-block__tabs--dark .c-block__tabs-accordion-content {
  background-color: #555555;
  color: #fff;
}

.c-block__testimonials {
  max-width: calc(100% - 70px);
}

.c-block__testimonials .owl-nav .owl-prev,
.c-block__testimonials .owl-nav .owl-next {
  background-color: #eeeeee;
}

.c-block__testimonials .owl-nav .owl-prev:hover,
.c-block__testimonials .owl-nav .owl-next:hover {
  background-color: #dddddd;
}

.c-block__testimonials .owl-nav .owl-prev.invisible, .c-block__testimonials .owl-nav .owl-prev.invisible:hover,
.c-block__testimonials .owl-nav .owl-next.invisible,
.c-block__testimonials .owl-nav .owl-next.invisible:hover {
  background-color: #fafafa;
}

.c-block__testimonials-item {
  background-color: #fafafa;
}

.c-block__testimonials-item .icon-quote {
  fill: #d6d6d6;
}

.c-block__testimonials-meta img {
  width: 3.75rem !important;
}

.c-block__testimonials-info p {
  font-size: 0.875rem;
}

.c-block__social-media-widget-instafeed a {
  background-color: #fafafa;
}

.c-block__social-media-widget-instafeed span {
  color: #bfbfbf;
  font-size: 0.875rem;
}

.c-block__newsletters-list-item-inner {
  max-width: 25.625rem;
  border: 1px solid #fafafa;
}

.c-instagram__data {
  background-color: rgba(0, 0, 0, 0.8);
}

.c-instagram__data-inner {
  max-width: 31.25rem;
  background-color: #fff;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
}

.c-block__contact-form .cform-container {
  left: -0.8125rem;
  width: calc(100% + 26px);
}

.c-page__hero {
  height: 25rem;
}

@media (max-width: 64rem) {
  .c-page__hero {
    height: 18.75rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-page__hero {
    height: 15.625rem;
  }
}

.c-page__hero--container {
  max-width: 85.375rem;
}

.c-pagination__link {
  color: #24eac3;
  font-size: 1.25rem;
}

@media (max-width: 72rem) {
  .c-pagination__link {
    font-size: 1rem;
  }
}

.c-pagination__link .icon {
  fill: #24eac3;
}

.c-pagination__link--current, .c-pagination__link:hover {
  border-color: #24eac3;
}

.c-project {
  border-bottom: 0.125rem solid #ddd;
}

@media (max-width: 48.0625rem) {
  .c-project {
    max-width: 250px !important;
  }
}

.c-project__title a:hover {
  color: #57accb;
}

.c-project__category {
  color: #777777;
}

.c-project__lead a {
  color: #57accb;
}

.c-project__gallery-image p {
  border: 1px solid #dddddd;
}

.c-section--primary {
  background-color: #28bdaa;
}

.c-section--secondary {
  background-color: #eeeeee;
}

.select2-container .select2-selection--single {
  height: 2.375rem;
  border: 0.0625rem solid #bfbfbf;
}

@media (max-width: 85.4375rem) {
  .select2-container .select2-selection--single {
    height: 2.375rem;
  }
}

@media (max-width: 64rem) {
  .select2-container .select2-selection--single {
    height: 2.375rem;
  }
}

@media (max-width: 48.0625rem) {
  .select2-container .select2-selection--single {
    height: 2.25rem;
    border-width: 0.0625rem;
  }
}

.select2-container .select2-selection--single.select2--small {
  height: 2.25rem;
}

.select2-container .select2-selection--single.select2--xsmall {
  height: 2.125rem;
  border: 0.0625rem solid #24eac3;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 2.1875rem;
  color: #24eac3;
}

@media (max-width: 85.4375rem) {
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 2.1875rem;
  }
}

@media (max-width: 64rem) {
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 2.1875rem;
  }
}

@media (max-width: 48.0625rem) {
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 2.125rem;
  }
}

.select2-container--default .select2-selection--single.select2--small .select2-selection__rendered {
  line-height: 2.125rem;
}

@media (max-width: 48.0625rem) {
  .select2-container--default .select2-selection--single.select2--small .select2-selection__rendered {
    line-height: 2.125rem;
  }
}

.select2-container--default .select2-selection--single.select2--xsmall .select2-selection__rendered {
  line-height: 2.125rem;
}

@media (max-width: 48.0625rem) {
  .select2-container--default .select2-selection--single.select2--xsmall .select2-selection__rendered {
    line-height: 1.875rem;
  }
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 2.1875rem;
}

@media (max-width: 85.4375rem) {
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 2.1875rem;
  }
}

@media (max-width: 64rem) {
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 2.1875rem;
  }
}

@media (max-width: 48.0625rem) {
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 2.125rem;
  }
}

.select2-container--default .select2-selection--single.select2--small .select2-selection__arrow {
  height: 2.25rem;
}

.select2-container--default .select2-selection--single.select2--xsmall .select2-selection__arrow {
  height: 2.125rem;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #24eac3 transparent transparent transparent;
  border-width: 0.4375rem 0.375rem 0 0.375rem;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #24eac3 transparent;
  border-width: 0 0.375rem 0.4375rem 0.375rem;
}

.select2-dropdown {
  background-color: #fff;
  border: 0.1875rem solid #24eac3;
}

.select2-dropdown.select2--xsmall {
  border-width: 0.0625rem;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  font-size: 1rem;
}

@media (max-width: 48.0625rem) {
  .select2-container .select2-selection--single .select2-selection__rendered {
    font-size: 1rem;
  }
}

@media (max-width: 48.0625rem) {
  .select2-results__option {
    font-size: 0.9375rem;
  }
}

.select2-results__options.first-as-label .select2-results__option:first-child {
  background-color: #28bdaa;
  color: #fff;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #eeeeee;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #999999;
  color: #fff;
}

.c-sidebar__custom-content {
  max-width: 21.875rem;
}

.c-sidebar--4 .c-sidebar__custom-content {
  max-width: 25rem;
}

.c-sidebar__widget {
  max-width: 21.875rem;
}

.c-sidebar--4 .c-sidebar__widget {
  max-width: 25rem;
}

.c-footer__sidebar .c-sidebar__widget {
  max-width: none;
}

.c-sidebar__header {
  border-bottom: 0.25rem solid #24eac3;
  color: #24eac3;
}

.widget_nav_menu ul li,
.widget_pages ul li,
.c-side-nav ul li,
.widget_recent_entries ul li,
.widget_rss ul li {
  /* stylelint-disable selector-no-qualifying-type */
  /* stylelint-enable selector-no-qualifying-type */
}

.widget_nav_menu ul li a,
.widget_pages ul li a,
.c-side-nav ul li a,
.widget_recent_entries ul li a,
.widget_rss ul li a {
  border: 0.0625rem solid #eeeeee;
  color: #555555;
  transition: all 300ms ease-in-out;
  /* stylelint-disable selector-no-qualifying-type */
  /* stylelint-enable selector-no-qualifying-type */
}

.widget_nav_menu ul li a:hover, .widget_nav_menu ul li a.c-side-nav__link--current, .widget_nav_menu ul li a.c-side-nav__sublink--current,
.widget_pages ul li a:hover,
.widget_pages ul li a.c-side-nav__link--current,
.widget_pages ul li a.c-side-nav__sublink--current,
.c-side-nav ul li a:hover,
.c-side-nav ul li a.c-side-nav__link--current,
.c-side-nav ul li a.c-side-nav__sublink--current,
.widget_recent_entries ul li a:hover,
.widget_recent_entries ul li a.c-side-nav__link--current,
.widget_recent_entries ul li a.c-side-nav__sublink--current,
.widget_rss ul li a:hover,
.widget_rss ul li a.c-side-nav__link--current,
.widget_rss ul li a.c-side-nav__sublink--current {
  background-color: #eeeeee;
}

.widget_nav_menu ul li.current-menu-item > a,
.widget_nav_menu ul li.current_page_item > a,
.widget_nav_menu ul li .c-side-nav__link--current > a,
.widget_nav_menu ul li .c-side-nav__link--current:hover > a,
.widget_pages ul li.current-menu-item > a,
.widget_pages ul li.current_page_item > a,
.widget_pages ul li .c-side-nav__link--current > a,
.widget_pages ul li .c-side-nav__link--current:hover > a,
.c-side-nav ul li.current-menu-item > a,
.c-side-nav ul li.current_page_item > a,
.c-side-nav ul li .c-side-nav__link--current > a,
.c-side-nav ul li .c-side-nav__link--current:hover > a,
.widget_recent_entries ul li.current-menu-item > a,
.widget_recent_entries ul li.current_page_item > a,
.widget_recent_entries ul li .c-side-nav__link--current > a,
.widget_recent_entries ul li .c-side-nav__link--current:hover > a,
.widget_rss ul li.current-menu-item > a,
.widget_rss ul li.current_page_item > a,
.widget_rss ul li .c-side-nav__link--current > a,
.widget_rss ul li .c-side-nav__link--current:hover > a {
  background-color: #eeeeee;
}

.widget__content-box-inner {
  max-width: 21.875rem;
}

.c-sidebar--4 .widget__content-box-inner {
  max-width: 25rem;
}

@media (max-width: 64rem) {
  .widget__content-box-inner {
    max-width: 26.25rem;
  }
}

@media (max-width: 48.0625rem) {
  .widget__content-box-inner {
    max-width: 23.75rem;
  }
}

.widget_x5_widget_upcoming_events p {
  border: 0.0625rem solid #eeeeee;
}

.widget_x5_widget_upcoming_events a {
  color: #000;
  transition: all 300ms ease-in-out;
}

.widget_x5_widget_upcoming_events:hover a {
  background-color: #eeeeee;
}

.widget__newsletter {
  background-color: #eeeeee;
}

.widget__newsletter-link {
  color: #555555;
}

.widget__newsletter-link:hover .widget__newsletter-label {
  color: #555555;
}

.c-footer__sidebar .c-sidebar__widget {
  max-width: none;
  margin: auto;
}

@media (max-width: 64rem) {
  .c-footer__sidebar .c-sidebar__widget {
    max-width: 21.875rem;
  }
  .c-sidebar--4 .c-footer__sidebar .c-sidebar__widget {
    max-width: 25rem;
  }
}

.c-footer__sidebar .widget__html-content ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.c-footer__sidebar .widget__html-content ul li:before {
  content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10' width='10'><circle cx='5' cy='5' r='4' stroke='#57accb' stroke-width='1' fill='#ffffff' fill-opacity='0' /></svg>");
  /* stylelint-disable-line string-quotes */
}

.c-footer__sidebar .widget__html-content ul li a {
  color: #57accb;
}

body.home .c-footer__sidebar {
  /* stylelint-disable-line selector-no-qualifying-type */
}

body.home .c-footer__sidebar .home-hide {
  /* stylelint-disable-line selector-no-qualifying-type */
  display: none;
}

.c-social-media__link {
  border: 0.125rem solid #000;
}

.c-social-media__link:hover {
  border-color: #24eac3;
}

.c-social-media__link:hover .c-social-media__icon {
  fill: #24eac3;
}

@media (min-width: 64rem) {
  .c-footer__colums-social-media .c-social-media {
    margin: 3.125rem 0;
  }
}

.u-fill {
  background: #fafafa;
}

body {
  font-size: 1rem;
}

p {
  font-size: inherit;
  line-height: 1.5rem;
}

p .u-text--enlarge {
  font-size: 1.625rem;
}

p .u-text--ensmall {
  font-size: 1rem;
}

@media (max-width: 105rem) {
  p .u-text--enlarge {
    font-size: 1.5rem;
  }
}

@media (max-width: 85.4375rem) {
  p .u-text--enlarge {
    font-size: 1.375rem;
  }
}

@media (max-width: 72rem) {
  p .u-text--enlarge {
    font-size: 1.25rem;
  }
}

@media (max-width: 48.0625rem) {
  p {
    font-size: 1rem;
  }
  p .u-text--enlarge {
    font-size: 1.125rem;
  }
  p .u-text--ensmall {
    font-size: 0.875rem;
  }
}

.u-text--light {
  font-weight: 300;
}
