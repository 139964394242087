.c-member__inner {
  text-align: left;
  border-bottom: none;
}

.c-member__image {
  img {
    max-width: rem_calc(150);
    height: auto;
  }
}

.c-member__title {
  background-color: transparent;
  font-size: rem-calc(16);
  margin-bottom: 0;
}

.c-member__link {
  padding: rem-calc(10 0 0);
  color: $color-link;

  &:hover {
    color: $color-link-hover;
  }
}

.c-member__content {
  padding-left: 0;
  padding-right: 0;
}

.c-member__email:before,
.c-member__dept-pos.notempty:after {
  display: block;
  margin: rem_calc(10 0);
  content: '';
  width: rem_calc(30);
  border-top: rem_calc(4) solid $color-bg-dark;
}
