.c-events-calendar {
  width: 100%;

  &--size-75 {
    @include bp-large {
      width: 75%;
    }
  }

  &--size-66 {
    @include bp-medium {
      width: 75%;
    }

    @include bp-large {
      width: 66%;
    }
  }

  &--size-50 {
    @include bp-medium {
      width: 75%;
    }

    @include bp-large {
      width: 66%;
    }

    @include bp-xlarge {
      width: 50%;
    }
  }

  .fc-header-toolbar {
    h2 {
      top: rem-calc(3);
      font-size: rem_calc(24);

      @include bp-xlarge('max') {
        top: rem-calc(5);
        font-size: rem_calc(18);
      }

      @include bp-large('max') {
        font-size: rem_calc(16);
      }
    }

    .fc-button {
      background-color: $color-theme;
      color: $color-white;

      @include bp-xlarge('max') {
        font-size: rem_calc(14);
      }

      @include bp-medium('max') {
        font-size: rem_calc(13);
      }

      &:hover {
        background-color: $color-link-hover;
      }
    }

    .fc-state-disabled,
    .fc-today-button {
      background-color: $color-theme-dark;

      &:hover {
        background-color: $color-link-hover;
      }
    }
  }

  .fc-day-header {
    @include bp-medium('max') {
      font-size: rem_calc(13);
    }
  }

  td.fc-today { /* stylelint-disable-line selector-no-qualifying-type */
    background-color: $color-grey-lighter;
  }

  .fc-event-container {
    .fc-event {
      padding: rem-calc(1 5);
      background-color: $color-theme;
      transition: $global-transition;

      @include bp-small('max') {
        padding: rem-calc(1 2);
      }

      &:hover {
        background-color: $color-theme-light;
      }
    }

    .fc-title {
      display: block;
      min-height: rem-calc(18);
    }
  }
}
