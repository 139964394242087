.c-btn {
  font-size: rem_calc(18);
  font-weight: 400;
  white-space: nowrap;
  padding: rem-calc(12 45);

  .c-icon {
    width: rem-calc(26);
    height: rem-calc(18);
  }

  @include bp-medium('max') {
    padding: rem-calc(6 30);
    font-size: rem_calc(17);
  }

  &.home-hide {
    font-size: rem_calc(24);

    @include bp-xxlarge('max') {
      font-size: rem_calc(20);
    }

    @include bp-xlarge('max') {
      font-size: rem_calc(18);
    }
  }
}

// parameters - $bg-color, $bg-color-hover, $color, $color-hover, $border, $border-hover: $border

.c-btn--primary {
  @include btn-style(transparent, $color-theme, $color-theme, $color-white, rem-calc(3) solid $color-theme);
}

.c-btn--primary-filled {
  @include btn-style($color-theme, transparent, $color-white, $color-theme, rem-calc(3) solid $color-theme);
}

.c-btn--secondary {
  @include btn-style(transparent, $color-theme-light, $color-theme-light, $color-white, rem-calc(3) solid $color-theme-light);
}

.c-btn--secondary-filled {
  @include btn-style($color-theme-light, transparent, $color-white, $color-theme-light, rem-calc(3) solid $color-theme-light);
}

.c-btn--link {
  @include btn-style(transparent, $color-link, $color-link, $color-white, rem-calc(3) solid $color-link);
}

.c-btn--link-filled {
  @include btn-style($color-link, transparent, $color-white, $color-link, rem-calc(3) solid $color-link);
}

.c-btn--link-underline {
  @include btn-style(transparent, transparent, $color-grey-dark, $color-link-hover, none);
  padding-left: 0;
  padding-right: 0;
  border: none;
  border-bottom: rem-calc(3) solid $color-link;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none !important;
    border-bottom: rem-calc(3) solid $color-link-hover;
  }

  .c-listing__item & {
    padding: 0;
    border: none;
    font-size: rem_calc(16);
    color: $color-theme;

    &:hover,
    &:focus {
      color: $color-link-hover;
    }
  }
}

.c-btn--primary-light {
  @include btn-style($color-theme, $color-theme-light, $color-white, $color-white, rem-calc(3) solid $color-theme, rem-calc(3) solid $color-theme-light);
}

.c-btn--secondary-light {
  @include btn-style($color-link, $color-link-hover, $color-white, $color-white, rem-calc(3) solid $color-link, rem-calc(3) solid $color-link-hover);
}

.c-btn--arrow-primary,
.c-btn--arrow-secondary {
  padding: rem-calc(3 10);
  border: none;
}

.c-btn--arrow-primary {
  color: $color-grey-dark;

  .c-icon {
    fill: $color-theme-dark;
  }

  &:hover,
  &:active,
  &:focus {
    color: $color-theme-dark;
  }
}

.c-btn--arrow-secondary {
  color: $color-grey-dark;

  .c-icon {
    fill: $color-theme;
  }

  &:hover,
  &:active,
  &:focus {
    color: $color-theme;
  }
}
