.c-pagination__link {
  color: $color-theme;
  font-size: rem_calc(20);

  @include bp-xlarge('max') {
    font-size: rem_calc(16);
  }

  .icon {
    fill: $color-theme;
  }

  &--current,
  &:hover {
    border-color: $color-theme;
  }
}
