.c-footer {
  background-color: $color-bg-dark;
  color: $color-white;

  &__column {
    @include bp-large('max') {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }

    &-header {
      h3 {
        border-color: $color-theme;
      }
    }
  }

  .c-footer__inner {
    max-width: 366px;

    @include bp-large {
      max-width: 100%;
    }
  }

  .c-social-media__link {
    border-color: $color-white;

    .c-social-media__icon {
      fill: $color-white;
    }
  }

  .js-google-map {
    max-width: rem_calc(330);
  }
}

.c-footer__copyright {
  background-color: $color-grey-extra-light;
  font-size: rem_calc(17);
  border-top: 1px solid $color-grey-lighter;

  .c-footer__logo {
    max-width: rem_calc(160);
  }
}
