.c-x5__table {
  background-color: $color-white;

  &--shadow {
    box-shadow: 0 0 35px 5px rgba(0, 0, 0, 0.3);
  }

  &--border-grey,
  &--header-bordered#{&}--border-grey th,
  &-rows--bordered#{&}--border-grey td,
  &-cols--bordered#{&}--border-grey th,
  &-cols--bordered#{&}--border-grey td,
  &-rows--striped-bordered#{&}--border-grey td,
  &-rows--striped-bordered-reverse#{&}--border-grey td,
  &-cols--striped-bordered#{&}--border-grey th,
  &-cols--striped-bordered#{&}--border-grey td,
  &-cols--striped-bordered-reverse#{&}--border-grey th,
  &-cols--striped-bordered-reverse#{&}--border-grey td {
    border-color: $color-grey;
  }

  &--border-black,
  &--header-bordered#{&}--border-black th,
  &-rows--bordered#{&}--border-black td,
  &-cols--bordered#{&}--border-black th,
  &-cols--bordered#{&}--border-black td,
  &-rows--striped-bordered#{&}--border-black td,
  &-rows--striped-bordered-reverse#{&}--border-black td,
  &-cols--striped-bordered#{&}--border-black th,
  &-cols--striped-bordered#{&}--border-black td,
  &-cols--striped-bordered-reverse#{&}--border-black th,
  &-cols--striped-bordered-reverse#{&}--border-black td {
    border-color: $color-black;
  }

  thead {
    background-color: $color-black;
    color: $color-white;
  }

  &--header-grey thead {
    background-color: $color-grey-light;
  }

  &--header-white thead {
    background-color: $color-white;
    color: $color-black;
  }

  td {
    font-size: rem_calc(14);
  }

  th, td {
    border-color: inherit;
  }

  &--header-bordered th {
    border-bottom: solid rem_calc(1);
  }

  &-rows--bordered td,
  &-rows--striped-bordered td,
  &-rows--striped-bordered-reverse td {
    border-bottom: solid rem_calc(1);
  }

  &-rows--bordered tr:last-child() td,
  &-rows--striped-bordered tr:last-child() td,
  &-rows--striped-bordered-reverse tr:last-child() td {
    border-bottom: none;
  }

  &-cols--bordered th,
  &-cols--bordered td,
  &-cols--striped-bordered th,
  &-cols--striped-bordered td,
  &-cols--striped-bordered-reverse th,
  &-cols--striped-bordered-reverse td {
    border-right: solid rem_calc(1);
  }

  &-cols--bordered th:last-child(),
  &-cols--bordered td:last-child(),
  &-cols--striped-bordered th:last-child(),
  &-cols--striped-bordered td:last-child(),
  &-cols--striped-bordered-reverse th:last-child(),
  &-cols--striped-bordered-reverse td:last-child() {
    border-right: none;
  }

  &-rows--striped tbody tr:nth-child(odd) td,
  &-rows--striped-bordered tbody tr:nth-child(odd) td,
  &-rows--striped-reverse tbody tr:nth-child(even) td,
  &-rows--striped-bordered-reverse tbody tr:nth-child(even) td {
    background-color: $color-grey-lighter;
  }

  &-cols--striped th:nth-child(odd),
  &-cols--striped td:nth-child(odd),
  &-cols--striped-reverse th:nth-child(even),
  &-cols--striped-reverse td:nth-child(even),
  &-cols--striped-bordered th:nth-child(odd),
  &-cols--striped-bordered td:nth-child(odd),
  &-cols--striped-bordered-reverse th:nth-child(even),
  &-cols--striped-bordered-reverse td:nth-child(even) {
    background-color: $color-grey-lighter;
  }

  &--border-grey td,
  &--border-grey th {
    border-color: $color-grey;
  }

  @include bp-medium {
    &--striped tbody tr:nth-child(even),
    &--striped-reverse tbody tr:nth-child(odd),
    &--striped-cols td:nth-child(even),
    &--striped-cols#{&}--header-white th:nth-child(even),
    &--striped-cols-reverse td:nth-child(odd),
    &--striped-cols-reverse#{&}--header-white th:nth-child(odd) {
      background-color: $color-grey-lighter;
    }
  }
}
